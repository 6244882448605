<template>
	<div class="news">
		<span class="overlay" :class="{active: isMenuOpen || isAccessModalOpen}"></span>
		<div class="hero-container">
			<Nav back />
			<div class="header header-standard">
				<HeaderNavBar back route="News"/>
			</div><!-- header -->
			<div class="header-image news-header-image huge">
                <img class="bg" v-if="post.image_url" :src="post.image_url"/>
            </div><!-- header image -->
			<div class="news-hero-content">
				<div class="info">
					<h1 v-if="post.title.length<120" class="news-title">{{ post.title }}</h1>
					<h1 v-else class="news-title">{{ post.title.substring(0,120)+"..." }}</h1>
					<span class="eyebrow">{{ post.created_at | moment('Do MMMM YYYY') }}</span>
				</div><!-- info -->
			</div>
		</div>
		<div class="content-wrap content-wrap-alt content-wrap-nav">
			<div class="news-content" v-html="post.content"></div><!-- news content -->
			<div class="section-gap">
				<div class="section-title-wrap section-title-wrap-big">
					<h2 class="section-title">Latest News</h2>
					<router-link :to="{ name: 'News' }" class="page-link page-link-grey">View more &raquo;</router-link>
				</div>
				<div class="news-card-wrap-alt">
					<NewsCard
                        v-for="article in latest"
                        :key="article.id"
						class="news-card-alt"
						:id="article.id"
						:image="article.image_url"
						:title="article.title"
						:date="article.created_at | moment('Do MMMM YYYY')"
					/>
				</div>
			</div>
		</div>

        <div ref="modalAccess" class="phone-modal with-nav" style="bottom: -600px;" :class="{active: isAccessModalOpen}">
            <span class="modal-line"></span>
            <div class="modal-title-bar">
                <div class="icon-wrap">
                    <RegisterIcon style="width: 27px; height: 27px;" class="icon register-icon" />
                    <span>Create your profile</span>
                </div><!-- icon wrap -->
                <GreyCloseIcon @click="closeAccessModal" style="width: 31px; height: 31px;" class="icon close" />
            </div><!-- modal title bar -->
            <div class="modal-message-block wide">
                <ProfileIcon class="profile-icon" style="width: 50px; height: 50px;" />
                <h3>Please create a profile to access this area.</h3>
                <p>This app will always be free, but creating a profile will give you greater access to the app.</p>
            </div>
            <Button routeName="/create-profile" icon="RegisterIcon" class="btn btn-green btn-icon btn-icon-white btn-icon-small btn-icon-right" text="Okay, I'll create a profile"></Button>
            <Button @click.native="openLoginScreen" icon="LoginIcon" class="btn btn-slate btn-icon btn-icon-white btn-icon-small btn-icon-right" text="I already have a profile"></Button>
            <a @click="closeAccessModal()" class="text-link text-link-grey">I'll create a profile another time &raquo;</a>
        </div><!-- modal -->

		<Menu />
	</div>
</template>
<script>
	import HeaderNavBar from '@/components/HeaderNavBar.vue';
	import NewsCard from '@/components/NewsCard.vue';
	import Nav from '@/components/Nav.vue';
	import Menu from '@/components/Menu.vue';
    import NewsService from '@/services/NewsService';
    import RegisterIcon from '@/assets/register.svg';
    import ProfileIcon from '@/assets/profile.svg';
    import GreyCloseIcon from '@/assets/close-grey.svg';
    import Button from '@/components/Button.vue';
	export default {
		name: 'News',
		components: {
			Nav,
			HeaderNavBar,
			NewsCard,
			Menu,
            RegisterIcon,
            ProfileIcon,
            GreyCloseIcon,
            Button
		},
		data () {
	        return {
                post: {},
                latest: []
	        };
	    },
        watch: {
            newsId () {
                this.getArticle();
            }
        },
	    methods: {
            getArticle () {
                return NewsService.getArticle(this.newsId).then((response) => {
                    this.post = response.data.article;
                    this.latest = response.data.latest;
                });
            },
            openLoginScreen () {
                this.$router.push({ name: 'Home', params: { showLoginModal: true } });
            },
            closeAccessModal () {
                this.$store.state.isAccessModalOpen = false;
            }
        },
	    computed: {
			isMenuOpen () {
	            return this.$store.state.isMenuOpen;
	        },
            newsId () {
                return this.$route.params.id;
            },
            isAuthenticated () {
                return this.$store.getters.isAuthenticated;
            },
            isAccessModalOpen () {
                return this.$store.state.isAccessModalOpen;
            }
		},
		mounted () {
            this.getArticle();
        }
	};
</script>
<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
	.news-header-image img.bg {
		opacity: 0.15;
	}
	.section-title-wrap-big {
		margin-bottom: 20px;
	}
	.news-hero-content {
		position: relative;
		text-align: left;
		display: flex;
	    justify-content: space-between;
	    margin: 0 28px;
	    align-items: flex-end;
	    min-height: 175px;
    	padding: 0 0 22px;
	    .news-title {
    		font-size: 26px;
    		line-height: 31px;
    		color: $white;
    		display: block;
    		margin-bottom: 12px;
    	}
    	.eyebrow {
    		color: $white;
    	}
	}
	.news-content ::v-deep {
		text-align: left;
		margin-bottom: 50px;
		iframe {
			width: 100%;
			margin: 10px 0 30px;
		}
		h2, h3, h4, h5 {
			color: $dark;
			font-size: 17px;
			letter-spacing: -0.5px;
			font-weight: $weight_600;
			line-height: 26px;
			margin-bottom: 30px;
		}
		h6 {
			color: $orange_base;
			font-size: 17px;
			letter-spacing: -0.5px;
			font-weight: $weight_600;
			line-height: 26px;
			margin-bottom: 22px;
			margin-bottom: 30px;
		}
		p {
			margin-bottom: 22px;
			a {
				color: $blue_base;
				text-decoration: underline;
				font-weight: $weight_500;
			}
		}
		ul {
			list-style: disc;
		    padding-left: 15px;
		    margin-bottom: 25px;
			li {
				font-family: "Inter", sans-serif;
			    font-weight: 400;
			    letter-spacing: -0.5px;
			    font-size: 17px;
			    line-height: 1.6em;
			    color: #6D7A8B;
			    margin-bottom: 10px;
			}
		}
	}
</style>
