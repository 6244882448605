import * as API from '@/services/API';

export default {
    getNews () {
        return API.apiClient.get('/news');
    },
    getArticle (id) {
        return API.apiClient.get(`/news/${id}`);
    }
};
